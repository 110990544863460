<template>

  <div id="contato" class="contact">
    <div class="container">
      <div class="row">
        <div class="col m6 s12 form-area">
          <h2>Enviar mensagem</h2>
          <p> 
            Responderemos o mais rápido possivel.
          </p>
          <form>
            <input v-model="name" class="browser-default" type="text" placeholder="Nome">
            <input v-model="email" class="browser-default" type="text" placeholder="Email">
            <textarea v-model="message" class="browser-default" rows="4" cols="50" placeholder="Sua Mensagem"></textarea>
            <v-btn @click="send" :disabled="isLoading">
              <v-progress-circular v-if="isLoading" style="margin-right: 10px" size="18" indeterminate width="2.5"  />
              Enviar
            </v-btn>
          </form>
        </div>
        <div class="col m6 s12 form-area">
          <h4>Fique em <b>Contato</b></h4>

          <div class="single">
            <v-icon>mdi-map-marker</v-icon>
            <b>Endereço:</b> Rua Camacua, número 41, Porto Canoa, Serra - ES
          </div>
          <div class="single">
            <v-icon>mdi-phone</v-icon>
            <b>Telefone:</b> (27) 99896-5368
          </div>
          <div class="single">
            <v-icon>mdi-at</v-icon>
            <b>Email:</b> contato@bsi.com.br
          </div>
          <div class="single">
            <v-icon>mdi-shield-account</v-icon>
            <b>Suporte:</b> sac@bsi.com.br - suporte@bsi.com.br
          </div>
          
          <br>
          <h4>Horario de <b>Atendimento</b></h4>

          <div class="single">
            <v-icon>mdi-alarm</v-icon>
            <b>Segunda à Quinta feira:</b> 8:30 - 17:30
          </div>

          <div class="single">
            <v-icon>mdi-alarm</v-icon>
            <b>Sexta Feira:</b> 8:30 - 16:00
          </div>

        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

import axios from "axios";

export default {
  name: 'BannerComponent',
  data: () => ({
    email: "",
    message: "",
    name: "",
    isLoading: false,
    success: null
  }),
  mounted(){

  },
  methods:{
    async send(){

      const { email, name, message } = this;

      if(!email || !message) return null;

      this.isLoading = true;


      const payload = {

        sender: {
          name,
          email
        },
        to: [{
          name: "BSI SITE",
          email: "bsisistemas.br@gmail.com"
        }],
        subject: "Email Padrão",
        textContent: message

      }

      const headers = {
        "accept": "application/json",
        "content-type": "application/json",
        "api-key": "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"
      }


      try {
        await axios.post("https://cors-anywhere.herokuapp.com/https://api.sendinblue.com/v3/smtp/email", payload, { headers })

        this.$swal({
          title: "Sucesso!",
          text: "Recebemos seu email e responderemos o quanto antes!",
          icon: "success",
          confirmButtonText: 'Fechar'
        });

      } catch (error) {

        this.$swal({
          title: "Error!",
          text: "Ocorreu um error, tente novamente mais tarde ou nos ligue!",
          icon: "error",
          confirmButtonText: 'Fechar'
        });
        
      } finally {
        this.name = "";
        this.email = "";
        this.message = "";
        this.isLoading = false;

      }


    }
   
  }
};
</script>

<style lang="scss" scope>

  .contact{
      background-color: #ededed;
      padding: 40px 20px;

      form{

        .v-btn{
          height: 50px;
          padding: 0 30px;
          border-radius: 4px;
          font-weight: 500;
          text-transform: none;

          background-color: #212529 !important;
          border-color: #212529 #212529 #0a0c0d;
          color: #FFF;
        }

        input, textarea{
          width: 100%;
          margin: 10px 0;
          border-radius: 4px;
          padding: 15px;
          background-color: white;
          color: #777;
          font-weight: 400;
          border: 1px solid #ccc;

          &:focus{
            outline: none;
          }
        }

        textarea{
          min-height: 100px;
        }

      }

      h4{
        letter-spacing: -1px;
        font-weight: 500;
        font-size: 19px;

        b{
          font-weight: bold;
        }
      }

      .single{
        display: flex;
        color: #777;
        align-items: flex-start;
        margin: 15px 0;
        font-size: 14px;

        b{
          color: black;
          margin-right: 5px;
        }

        i{
          margin-right: 10px;
          border-radius: 50%;
          height: 26px;
          font-size: 16px;
          text-align: center;
          width: 26px;
          color: #02b7d0;
          border: 1px solid #02b7d0;
        }
      }
    
  }

</style>