<template>

    <div id="home" class="main-banner">
        <v-icon class="go-down">mdi-chevron-down</v-icon>
        <div class="content">
            <div class="container">
            <div class="row">
                <div class="col m6 s12 box">
                <h1>
                    BSI Sistemas<br> 
                    <vue-typer eraseStyle="backspace" :eraseDelay="50" :text="loopText"></vue-typer>
                </h1>
                <h4>Tudo o que você precisa em um só lugar, Alta performance, Tecnologia e DNA digital. <br> Entre em contato com nossos consultores.</h4>
                <v-btn class="btn blue">
                    Conheça mais
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                </div>
                <!-- <div class="col m6 s12 box"></div> -->
            </div>
            </div>
        </div>
        <video muted playsinline autoplay loop>
            <source src="https://portotheme.com/html/porto/8.1.0/video/memory-of-a-woman.mp4" type="video/mp4">
        </video>
    </div>
  
</template>

<script>

import { VueTyper } from 'vue-typer';

export default {
  name: 'MainComponent',
  components:{
    VueTyper
  },
  data: () => ({
      loopText: ["Integração", "Produtividade", "Inovação", "Relacionamento"]
  }),
  mounted(){

  },
  methods:{
   
  }
};
</script>

<style lang="scss">

.main-banner{

    position: relative;
    width: 100%;
    overflow: hidden;

    .v-icon.go-down{
        position: absolute;
        bottom: 60px;
        width: 40px;
        left: calc(50% - 40px);
        color: white;
        z-index: 2;
        -moz-animation: goDown 1.2s infinite;
        -webkit-animation: goDown 1.2s infinite;
        animation: goDown 1.2s infinite;
        font-size: 36px;
    }

    .content{

        .vue-typer{
            .caret{
                width: 4px;
                height: 80%;
                margin-left: 10px;
                background-color: white;
            }
            span{
                color: white;
            }
        }

        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;

        .box{
            color: white;
            margin-bottom: 40px;
        }
        
        h1{
            color: white;
        }

        h4{
            color: white;
        }

        .v-btn.blue{
            background-color: #02b7d0;
            color: white;
            display: flex;
            align-items: center;
            font-weight: 500;
            height: 50px;
            border-radius: 4px;
            padding: 0 20px;

            .v-icon{
                margin-left: 5px;
            }
        }

    }

    video{
        object-fit: cover; 
        background-size: cover;
        height: 100vh;
        min-width: 100%;
    }

    &::before{
        content: "";
        background-color:rgba(0, 0, 0, .8);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

}

@keyframes goDown {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(40px);
    }
}

</style>