<template>

  <v-dialog fullscreen hide-overlay content-class="project-modal" v-model="open" transition="dialog-bottom-transition">
    
    <div class="wrapper">

      <a class="close" @click="open = false">
        <v-icon>mdi-close</v-icon>
      </a>

      <div class="content" :class="'project-' + project.id">

        <div class="modal-header">
          <div class="modal-switch">
            <div class="wrapper" v-if="prev && prev.title" @click="changeProject(project.id - 1)">
              <v-icon>mdi-arrow-left</v-icon>
              <div>
                <h4 class="caption">Projeto Anterior</h4>
                <h4>{{prev.title}}</h4>
              </div>
            </div>
          </div>
          <div class="project-title">
            <h2>{{project.title}}</h2>
          </div>
          <div class="modal-switch right">
            <div class="wrapper" v-if="next && next.title" @click="changeProject(project.id + 1)">
              <div>
                <h4 class="caption">Proximo Projeto</h4>
                <h4>{{next.title}}</h4>
              </div>
              <v-icon>mdi-arrow-right</v-icon>
            </div>
          </div>
        </div>

        <div v-for="(desc, index) in project.description" :key="index" class="project-description">

          <v-row v-if="index % 2 == 0 || isMobile">
            <v-col cols="12" md="6">
              <v-carousel v-if="isArray(desc.img)">
                <v-carousel-item v-for="(img, indexImg) in desc.img" :key="indexImg" eager>
                  <img width="80%" :src="img">
                </v-carousel-item>
              </v-carousel>
              <img v-else width="80%" :src="desc.img">
            </v-col>
            <v-col cols="12" md="6">
              <h3>{{desc.title}}</h3>
              <p v-html="desc.text"></p>
            </v-col>
          </v-row>

          <div v-if="!isMobile">

            <v-row v-if="index % 2 == 1">
              <v-col cols="12" md="6">
                <h3>{{desc.title}}</h3>
                <p>{{desc.text}}</p>
              </v-col>
              <v-col cols="12" md="6">
                <v-carousel v-if="isArray(desc.img)">
                  <v-carousel-item v-for="(img, indexImg) in desc.img" :key="indexImg" eager>
                    <img width="80%" :src="img">
                  </v-carousel-item>
                </v-carousel>
                <img v-else width="80%" :src="desc.img">
              </v-col>
            </v-row>
            
          </div>

        </div>
      
      </div>

    </div>
    
  </v-dialog>
</template>

<script>

export default {
  name: 'ProjectModalComponent',
  data: () => ({
    open: true,
    isMobile: false
  }),
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  components: {
  },
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    next: {
      type: Object,
      default: () => ({})
    },
    prev: {
      type: Object,
      default: () => ({})
    }
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods:{
    onResize () {
      this.isMobile = window.innerWidth < 980
    },
    changeProject: function(newIndex){
      this.$emit("change", newIndex);
    },
    isArray: function(img){
      return Array.isArray(img);
    }
  },
  watch: {
    open: {
      handler(newValue){

        if(!newValue) {

          setTimeout(() => {
            this.$emit("closeModal");
          }, 500);

        }

        if(newValue){
          document.body.getElementsByClassName("v-application--wrap")[0].style.display = 'none';
        }
        if(!newValue){
          document.body.getElementsByClassName("v-application--wrap")[0].style.display = 'flex';
          this.$vuetify.goTo("#projetos", { duration: 0, offset: -200 })
        }

      },
      immediate: true
    }
  },
};
</script>

<style lang="scss">

  .v-dialog{

    .wrapper{
      background-color: white;
    }

    .v-responsive, .v-carousel{
      height: 300px !important;
    }

    &.project-modal{
      // width: 88%;
      overflow-y: auto;
      border-radius: 0;
      position: relative;
      max-width: none;
      max-height: none !important;
      padding: 20px 80px;

      .project-description{
        padding: 40px;
      }

      .project-description h3{
        letter-spacing: -.05em;
        font-weight: 600;
        margin: 0;
        line-height: 2.5em;
        font-size: 1.2em;
      }

      .project-description h3 b{
        font-weight: bold;
      }

      p{
        color: #777;
        line-height: 26px;
        font-size: 14px;
        margin: 0 0 20px;
      }

      .v-carousel{

        img{
          display: block;
          margin: 0 auto;
          // margin-left: -5%;
        }

        .v-carousel__controls{
          display: none;
        }
      }

      .modal-header{
        padding: 50px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .project-title h2{
            font-size: 1.8em;
            margin: 0;
            color: #212529;
        }

        .modal-switch{
          cursor: pointer;
          min-width: 150px;

          &.right{
            h4{
              text-align: right;
              margin-right: 10px;
            }
          }

          .wrapper{
            display: flex;
            align-items: center;
            flex-direction: row;
           
          }
          .v-icon{
            margin-right: 10px;
          }

          h4{
            color: #212529;
            font-size: .9em;
            margin: 0;
            font-weight: bold;
          }

          .caption{
            text-transform: uppercase;
            font-size: 13px;
            opacity: .4;
            font-weight: 600;
          }

        }
      }

      .close{
        position: absolute;
        cursor: pointer;
        opacity: .6;
        right: 15px;
        top: 15px;
        width: 24px;
        font-weight: bold;
        color: #000;
        .v-icon{
          font-size: 28px;
          font-weight: bold;
        }
      }
      .close:hover{
        opacity: 1;
      }

    }
  }

  @media screen and (max-width: 980px) {

    .v-carousel{

      height: 250px !important;

      .v-image{
        height: 250px;
      }
    }

    .project-description{
      padding: 20px !important;
    }

    .project-title{
      font-size: 14px;

    }


    .modal-header{
      align-items: center;
      h4{
        display: none;
      }
    }

    .modal-switch{
      min-width: 40px !important;
      height: 24px;

      &.right{
        .v-icon{
          margin-left: auto;
        }
      }
    }

  }
  
</style>