<template>
  <header :class="{'shrink' : shrink}">

    <div class="container">

      <div class="logo">
          <img width="100px" src="@/assets/logos/logov1-transparent-white.png" alt="">
      </div>

      <div class="mobile-nav">
        <Slide right>
          <img width="150px" height="auto" src="@/assets/logos/logov1-transparent.png" alt="">
          <a @click="$vuetify.goTo(path, options)" v-for="(nav, index) in navs" v-bind:key="index">{{nav.title}}</a>
        </Slide>
      </div>

      <div class="nav desktop-nav">
          <a v-for="(nav, index) in navs" v-bind:key="index" @click="$vuetify.goTo(nav.path, options)" >{{nav.title}}</a>
      </div>

    </div>
  </header>
</template>

<script>

import { Slide } from 'vue-burger-menu';


export default {
  name: 'HeaderComponent',
  components: {
    Slide
  },
  props:{
    shrink:{
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    navs: [
      {title: "Home", path: "#home"},
      {title: "Projetos", path: "#projetos"},
      {title: "Clientes e Parceiros", path: "#clientes"},
      {title: "Contatos", path: "#contato"},
    ]
  }),
  mounted(){
  },
  computed: {
    options() {
      return {
        offset: 70,
      }
    },
  },
  methods:{
  }
};
</script>

<style lang="scss">

  .mobile-nav{
    display: none;
  }
  header{
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    position: fixed;
    z-index: 5;
    transition: height 0.3s ease;

    &.shrink{
      background-color: #212529;
      height: 70px;
    }

    .container{
      display: flex;
      align-items: center;
      height: 100%;
    }

    .logo{
      display: flex;
      flex: .6;
    }

    .nav{
      display: flex;
      flex: .6;
      justify-content: space-around;

      a{
        padding: 10px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.5px;
        text-transform: uppercase;
        transition: all .1s ease-out;
        text-decoration: none;
      }

      a:hover, header .nav a.active{
        /* font-weight: 800; */
        color: #02b7d0;
      }

    }

  }

  .mobile-nav{
    .bm-item-list{
      a{
        padding: 15px;
        cursor: pointer;
        color: #72848c;
      }
    }
    .bm-menu{
      background-color: white;
    }
    .bm-burger-button{
      top: 20px;
      right: 20px !important;
      .bm-burger-bars{
        background-color: white;
        height: 15%;
      }
    }
  }

  @media screen and (max-width: 980px) {

    header .desktop-nav{
      display: none;
    }

    header .mobile-nav{
      display: initial;
    }

  }


</style>