<template>

  <div class="intro-action">
      <div class="container">
          <v-row class="row" no-gutters>
            <v-col md="8" cols="12">
                <h3>Vamos ser digital? Nós temos o caminho.</h3>
                <p>Com a nossa inteligência digital, otimizamos o seu processo, aumentamos sua produtividade <br> com a ultilização de menor recurso.</p>
            </v-col>
            <v-col md="4" cols="12" class="btn-area">
              <v-btn>Falar com um consultor</v-btn>
            </v-col>
          </v-row>
      </div>
  </div>

</template>

<script>

export default {
  name: 'BannerComponent',
  components:{
  },
  data: () => ({
  }),
  mounted(){

  },
  methods:{
   
  }
};
</script>

<style lang="scss" scoped>

  .intro-action{

    background-color: #02b7d0;
    padding: 40px 0;

    h3{
      color: white;
      margin-bottom: 5px;
    }

    p{
      color: white;
      margin: 20px auto;
      font-weight: 300;
      font-size: 15px;
    }

    .btn-area{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .v-btn{
      height: 50px;
      padding: 0 30px;
      border-radius: 4px;
      font-weight: 500;
      text-transform: none;
      background-color: #212529 !important;
      border-color: #212529 #212529 #0a0c0d;
      color: #FFF;
    }
      
  }

  @media screen and (max-width: 980px) {

    .intro-action{
      .v-btn{
        margin-right: auto;
      }
    }


  }


</style>