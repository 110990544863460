<template>
    <div class="project-wrapper">
        <div :class="'single project-' + id">
          <img :src="img">
          <div class="project-title">
              <h4>{{title}}</h4>
              <h5>{{categories}}</h5>
          </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SingleProject',
  props: {
    id: {
      type: Number,
      default: -1
    },
    title: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    categories: {
      type: String,
      default: ""
    }
  },
  data: () => ({
  }),
  mounted(){

  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>

.single{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    overflow: hidden;
    
    // &.project-1{
    //   img{
    //     height: 100%;
    //     width: auto;
    //     margin-top: 0;
    //   }
    // }

    .project-title{
      
      position: absolute;
      bottom: 5%;
      left: 0px;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h4{
          background-color: rgba(33, 37, 41, 0.9);
          color: white;
          font-weight: 600;
          letter-spacing: -.05em;
          max-width: 90%;
          font-size: 17px;
          padding: 16px 20px 20px;
          display: inline-block;
          margin: 0;
      }

      h5{
          background-color: #02b7d0;
          color: white;
          border-radius: 2px;
          font-size: 0.6em;
          padding: 2px 12px;
          margin-left: 20px;
          text-transform: uppercase;
          line-height: 2.3;
          font-weight: 600;
          display: inline-block;
          margin-top: -12px;
      }

    }

    img{
        width: 110%;
        background-color: black;
        height: 210px;
        margin-left: -2%;
        transition: all 0.3s ease;
    }

    &:hover img{
        transform: scale(1.1, 1.1);
        transform-style: unset;
        opacity: .8;
    }
}

</style>