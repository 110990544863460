<template>
  <v-app>
    
    <Header :shrink="shrink" />
    <Main />
    <Aspects />
    <Projects />
    <Clients />
    <Banner />
    <Contact />

    <footer>
       © Copyright 2020. Todos Direitos Reservados BSI Brasil registrada sob o CNPJ 39.877.419.0001/61.
    </footer>

  </v-app>
</template>

<script>

import Header from '@/components/Header';
import Main from '@/components/Main';
import Aspects from '@/components/Aspects';
import Projects from '@/components/Projects';
import Clients from '@/components/Clients';
import Banner from '@/components/Banner';
import Contact from '@/components/Contact';

export default {
  name: 'App',
  components: {
    Header,
    Main,
    Aspects,
    Banner,
    Projects,
    Clients,
    Contact
  },
  data: () => ({
    shrink: false
  }),
  mounted(){
    window.addEventListener("scroll", () => {
      const position = window.pageYOffset || document.documentElement.scrollTop;
      this.shrink = position > 100 ? true : false;
    });
  }
};
</script>


<style lang="css">

  html{
    overflow-y: auto;
  }

  footer{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    background-color: #212529;
    padding: 40px 0;
  }

  h1, h2, h3, h4, h5, h6 {
      color: #444;
      font-weight: 600;
      line-height: 1.5;
      margin: 0 0 30px 0;
  }

  h1{
      font-size: 3.2em;
      font-weight: 800;
  }

  h2{
      font-size: 2.2em;
      font-weight: 700;
  }

  h3{
      font-size: 1.4em;
      font-weight: 400;
  }

  h4{
      font-size: 1.1em;
      font-weight: 500;
  }

  .text-center{
      text-align: center;
  }

  @media screen and (max-width: 980px) {

    h2{
      font-size: 32px;
    }

    .container{
      width: 86% !important;
    }

  }


</style>