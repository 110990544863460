<template>

  <div class="aspects">
      <div class="container">

        <!-- <div class="title">
          <h2 class="text-center">Conheça mais sobre nossos parceiros e clientes</h2>
          <p class="text-center"> 
            CONECTAMOS PESSOAS E SERVIÇOS
          </p>
        </div> -->

        <v-row class="row" no-gutters>

          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-face-agent</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>SUPORTE AO USUARIO</h4>
                <p>Estamos disponíveis de forma online e acompanhamos em real time as atividades
operacionais, equipe em pronto atendimento, considerando: a gestão, usuários,
profissionais, geolocalização e informações analíticas/estratégicas.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-cloud</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>ESTRUTURA 100% NUVEM</h4>
                <p>Com o cloud computing todo recurso computacional é sob demanda, reduzindo os custos com escalabilidade ilimitada.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-server-security</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>BACKUPS AUTOMATIZADOS</h4>
                <p>Para aumentar a segurança dos seus dados nossa plataforma permite backups periodicos por semana, dia ou até mesmo a cada hora.</p>
              </v-col>
            </v-row>
          </v-col>
         
        </v-row>
        <br>
        <v-row class="row" no-gutters>

          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-lead-pencil</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>PERSONALIZAÇÃO</h4>
                <p>Totalmente personalizado e com alta tecnologia, essa ferramenta disponível para WEB e
MOBILE, poderá ser utilizada por diversas atividades, assim como: Atendimento Médico,
Atendimento Homecare, Medicina do Trabalho, Realização de Periódico, Contratação de
Serviços, Entrevista de Emprego, Pesquisa Organizacional, Relacionamento em Grupo,
Controle de Empregados, Pesquisas a fins e muito mais.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-currency-usd</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>CUSTO REDUZIDO</h4>
                <p>Diante dos nossos estudos e experiência no mercado, entregamos ao cliente um sistema de alta aderência as suas atividades, em consequência implantamos mais rápido e com baixo custo.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" cols="12" class="single">
            <v-row class="row" no-gutters>
              <v-col md="1" cols="12"><v-icon>mdi-api</v-icon></v-col>
              <v-col md="10" cols="12" style="padding-left: 20px">
                <h4>INTEGRAÇÃO</h4>
                <p>Aplicamos em nossa ferramenta a integração de ponta a ponta, aqui não há lugar para o retrabalho, a ferramenta de forma conectada trabalha para você. Fornecemos ao mercado um sistema para gestão de plano de saúde de alta performance com tecnologia totalmente integrada, contemplando todas as atividades e interação automática com os sistemas legados.</p>
              </v-col>
            </v-row>
          </v-col>
         
        </v-row>

      </div>
  </div>

</template>

<script>

export default {
  name: 'AspectsComponent',
  components:{
  },
  data: () => ({
  }),
  mounted(){

  },
  methods:{
   
  }
};
</script>

<style lang="scss" scoped>

  .aspects{

    background-color: #02b7d0;
    padding: 40px 0;

    .single{
      color: white;
      .v-icon{
        color: white;
        font-size: 32px;
      }
      h4{
        color: white;
        font-weight: bold;
        margin-bottom: 14px;
      }
      p{
        opacity: .8;
        font-size: 14px;
        line-height: 26px;
      }
    }

  }

</style>