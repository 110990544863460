<template>

  <div id="clientes" class="clients">
        
    <div class="container">

      <div class="title">
        <h2 class="text-center">Conheça mais sobre nossos parceiros e clientes</h2>
        <p class="text-center"> 
          CONECTAMOS PESSOAS E SERVIÇOS
        </p>
      </div>

      <div class="list">

        <flickity ref="flickity" :options="flickityOptions" class="main-carousel">

          <div class="carousel-cell">
            <img width="100%" src="@/assets/partness/agtop.png">
          </div>

          <div class="carousel-cell">
            <img width="100%" src="@/assets/partness/elife.png">
          </div>

          <div class="carousel-cell">
            <img width="100%" src="@/assets/partness/formar.png">
          </div>

          <div class="carousel-cell">
            <img width="110%" src="@/assets/partness/codex.png">
          </div>

          <div class="carousel-cell">
            <img width="100%" src="@/assets/partness/enfermeira.png">
          </div>

          <div class="carousel-cell">
            <img width="100%" src="@/assets/partness/gamavix.png">
          </div>
          
        </flickity>

      </div>
      <br>
      <div class="testimonials">
        
        <flickity ref="flickity" :options="testimonialsFlickity" class="main-carousel">

          <div class="carousel-cell">
            <p>Conectamos ao futuro, optmização de tempo e ganho de produtividade.</p>
            <p class="owner"><small><b>Marcelo, GRUPO FORMAR</b></small></p>
          </div>

          <div class="carousel-cell">
            <p>Através da ferramenta de vendas ficamos mais proximos aos nossos clientes, mais oportunidades de vendas.</p>
            <p class="owner"><small><b>Ludmila, ELIFE</b></small></p>
          </div>
          
          <div class="carousel-cell">
            <p>Mais conforto para as minhas pacientes, posso atende-las da minha residência, ambas não precisamos sair de casa.</p>
            <p class="owner"><small><b>Ana Carolina, Consultório de Enfermagem</b></small></p>
          </div>
          
        </flickity>

      </div>
    </div>

  </div>
</template>

<script>

import Flickity from 'vue-flickity';

export default {
  name: 'BannerComponent',
  components: {
    Flickity
  },
  data: () => ({
    flickityOptions: {
      contain: true,
      autoPlay: '1000',
      pageDots: false,
      prevNextButtons: false,
      // wrapAround: true
    },
    testimonialsFlickity: {
      contain: true,
      autoPlay: '1000',
      prevNextButtons: false,
    }
  }),
  mounted(){

  },
  methods:{
   
  }
};
</script>

<style lang="scss" scoped>

  .testimonials{
    width: 75%;
    display: block;
    margin: 10px auto;

    .carousel-cell{
      color: white;
      width: 100%;

      .owner{
        margin: 20px auto;
      }

      p{
        width: 80%;
        font-size: 18px;
        margin: 0 auto;
        color: white;
        text-align: center;

        &::before{
          color: #0099e6;
          display: block !important;
          font-weight: 300;
          left: 10px;
          top: 0;
          content: "“";
          font-size: 80px;
          font-style: normal;
          line-height: 1;
          position: absolute;
        }
        &::after{
          color: #0099e6;
          font-weight: 300;
          display: block !important;
          right: 10px;
          font-size: 80px;
          font-style: normal;
          line-height: 1;
          position: absolute;
          bottom: -0.5em;
          content: "”";
        }
      }
    }
  }

  .clients{
    background: url('~@/assets/bg-one-page-1-1.jpg');
    position: relative;
    background-size: cover;
    background-position: center;

    .container{
      z-index: 2;
      position: relative;
      padding: 60px 0;
    }

    .title{ 
      h2{
        color: white;
      }
      p{
        color: #777;
        margin: 20px auto;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .list{
      padding: 20px 0;
      min-height: 80px;

      .carousel-cell{
        opacity: .75;
        width: 220px;
        min-height: 80px;
        padding: 0 20px;
        cursor: pointer;
        display: flex;
        justify-items: center;
        align-items: center;

        &:hover{
          opacity: 1;
        }
      }

    }

    &::before{
      content: "";
      background-color: #212529;
      opacity: .95;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

  }
</style>