<template>

  <div id="projetos" class="projects">
    
    <Expanded 
      v-if="activeProject" 
      @closeModal="activeProject = null"
      :next="projects.find(value => value.id === activeProject  + 1)"
      :prev="projects.find(value => value.id === activeProject  - 1)"
      :project="projects.find(value => value.id === activeProject)"
      @change="(id) => activeProject = id"
    />

    <div class="container">

      <div class="title">
        <h2 class="text-center">A gestão da sua empresa na palma da sua mão com: Inovação, personalização, estabilidade e segurança.</h2>
        <p class="text-center"> 
          Entregamos produtos com evolução digital disruptiva conectando a sua atividade no amanhã. <br> Sua confiança é importantíssima, oferecemos infraestrutura com gerenciamento online.
        </p>
      </div>
      <br>

      <v-row>
        
        <v-col md="3" cols="12" v-for="(project, index) in projects" v-bind:key="index" @click="activeProject = project.id">
          <Single :id="project.id" :categories="project.categories" :title="project.title" :img="project.thumbnail" />
        </v-col>

      </v-row>

      
    </div>
  </div>
  
</template>

<script>

import Expanded from './expanded';
import Single from './single';

export default {
  name: 'ProjectsComponent',
  components:{
    Expanded,
    Single
  },
  data: () => ({
    activeProject: null,
    projects: [
      { 
        id: 1, 
        title: "Tele Atendimento", 
        categories: "Mobile & Web", 
        thumbnail: require("@/assets/prints/covid/mont1.jpg"),
        description: [
          // {img: require("@/assets/prints/covid/mont1.jpg"), title: "Tele Atendimento", text: ""},
          {img: [require("@/assets/prints/covid/mont5.jpg"), require("@/assets/prints/covid/mont4.jpg")], title: "Tele Atendimento", text: "Diante desse cenário da necessidade de evolução da nossa sociedade em relação a utilização de plataformas eletrônicas para realização das atividades profissionais e pessoais, nós da BSI com o olhar e DNA digital, oferecemos ao mercado esse produto teleatendimento com total aderência a esse momento."},
          {img: require("@/assets/prints/covid/mont2.jpg"), title: "Personalização Inteligente", text: "totalmente personalizado e com alta tecnologia, essa ferramenta disponível para WEB e MOBILE, poderá ser utilizada por diversas atividades, assim como: Atendimento Médico, Atendimento Homecare, Medicina do Trabalho, Realização de Periódico, Contratação de Serviços, Entrevista de Emprego, Pesquisa Organizacional, Relacionamento em Grupo, Controle de Empregados, Pesquisas a fins e muito mais."},
          {img: require("@/assets/prints/covid/mont3.jpg"), title: "Atividades Acompanhadas.", text: "Entregamos as informações de forma online e acompanhamento real time das atividades operacionais considerando: a gestão, usuários, profissionais, geolocalização e informações analíticas/estratégicas."},
          {img: [require("@/assets/prints/covid/w_exames.jpg"), require("@/assets/prints/covid/w_prestador.jpg"), require("@/assets/prints/covid/w_call.jpg")], title: "Informações e Notícias", text: "Em tempos de ganho de processo, aplicamos em nossa ferramenta a integração de ponta a ponta, aqui não há lugar para o retrabalho, a ferramenta de forma conectada trabalha para você. Temos um grande ganho nessa ferramenta, atribuímos o atendimento de forma virtual e assinaturas eletrônicas, em alta tecnologia eliminamos a necessidade do papel em todos os processos, através das nossas assinaturas digitais e eletrônicas, adequamos todas as atividades com necessidade de assinatura, através do certificado digital, token, qrcode e mais. Com muito cuidado e atenção aos deveres legais, implantamos com o cumprimento de todas as exigências, dentre outras reforçamos aqui o cumprimento a lei LGPD, criptografia em todas as plataformas, proteção de dados com todos requisitos preenchidos."}
        ]
      },
      { 
        id: 2, 
        title: "Vendas Ao Vivo", 
        categories: "Mobile & Web", 
        thumbnail: require("@/assets/prints/vendas/mont1.jpg"),
        description: [
          { img: [require("@/assets/prints/vendas/mont7.jpg"), require("@/assets/prints/vendas/mont2.jpg")], title: "Produto em Foco", text: "Em atendimento as necessidades das plataformas de vendas e e commerce, oferecemos essa ferramenta para estimular e possibilitar o aumento das vendas. Implantamos com o cumprimento de todas as exigências legais, dentre outras reforçamos aqui o cumprimento a lei LGPD, criptografia em todas as plataformas e proteção de dados com todos requisitos preenchidos."},
          { img: require("@/assets/prints/vendas/mont1.jpg"), title: "Venda Confirmada", text: "Essa inovadora ferramenta em alta tecnologia realizamos uma conexão ao vivo, entre o consumidor e vendedor, temos aqui o objetivo de aproximar e potencializar a venda do seu produto ou serviço."},
          { img: require("@/assets/prints/vendas/mont3.jpg"), title: "Aplicações Multiplataformas", text: "Disponível para WEB e MOBILE, em seu site facilitamos a atração do consumidor ao seu produto ou serviço, em uma nova metodologia de venda, construímos uma interação objetiva. De forma muito fácil e dinâmica é possível falar ao vivo com o cliente, apresentar o produto, chat disponível de forma espontânea para interação e troca de informações."},
          { img: [ 
            require("@/assets/prints/vendas/w_vendedores.jpg"),
            require("@/assets/prints/vendas/w_horarios.jpg") ], title: "Garantia Operacional", text: "Entregamos as informações de forma online e acompanhamento real time dos atendimentos, geolocalização, dos consumidores, dos vendedores e informações estratégicas."},
          { img: require("@/assets/prints/vendas/w_custom.jpg"), title: "Personalização Inteligente", text: "Possibilitamos a sua empresa realizar a sua marca, imagens, textos e horários de funcionamento podem ficar em seu formato e poderá se alterado a qualquer momento de acordo com o desejado."}
        ]
      },
      { 
        id: 3, 
        title: "BSI Saúde", 
        categories: "Mobile & ERP", 
        thumbnail: require("@/assets/integration.jpg"),
        description: [
          {img: require("@/assets/integration.jpg"), title: "Gestão de Saúde em um só lugar", text: "Fornecemos ao mercado um sistema para gestão de plano de saúde de alta performance com tecnologia totalmente integrada, contemplando todas as atividades e interação automática com os sistemas legados, uma aplicação inovadora que, como nossa empresa, tem o DNA digital."},
          {img: require("@/assets/seo.jpg"), title: "Gestão Analítica", text: "Entregamos as informações de forma online e acompanhamento real time das autorizações, custo, receitas, despesas e informações estratégicas. Aqui a sua empresa realiza todas as atividades de forma eletrônica baseado nas informações de entrada em nossa ferramentas de autorizações on line interligadas com o sistema de gestão ERP."},
          {img: require("@/assets/flat1.png"), title: "Baixo Custo de Implantação", text: "Diante dos nossos estudos e experiência no mercado, entregamos ao cliente um sistema de alta aderência as suas atividades, em consequência implantamos mais rápido e com baixo custo. <br /><br /> Somos uma empresa de alta e atualizada tecnologia, eliminamos a necessidade do papel em todos os nossos processos, sim é verdade! Através das nossas assinaturas digitais e eletrônicas, adequamos todas as atividades da operadora com necessidade de assinatura, através do certificado digital, token, qrcode e mais. <br /><br />Preocupados com as exigências legais, implantamos com o cumprimento de todas as demandas legais, assim como a ANS, Receita Federal e mais, reforçamos aqui o cumprimento a lei LGPD, criptografia em todas as plataformas, proteção de dados com todos requisitos preenchidos."},
          {img: require("@/assets/flat3.jpg"), title: "Proteção Legal", text: "Preocupados com as exigências legais, implantamos com o cumprimento de todas as demandas legais, assim como a ANS, Receita Federal e mais, reforçamos aqui o cumprimento a lei LGPD, encriptados em todas as plataformas, proteção de dados com todos requisitos preenchidos."},
          {img: require("@/assets/flat2.jpg"), title: "Garantia Operacional", text: "Preocupados com as exigências legais, implantamos com o cumprimento de todas as demandas legais, assim como a ANS, Receita Federal e mais, reforçamos aqui o cumprimento a lei LGPD, encriptados em todas as plataformas, proteção de dados com todos requisitos preenchidos. <br /><br />Somos uma empresa de alta e atualizada tecnologia, eliminamos a necessidade do papel em todos os nossos processos, sim é verdade! Através das nossas assinaturas digitais e eletrônicas, adequamos todas as atividades da operadora com necessidade de assinatura, através do certificado digital, token, qrcode e mais."}
        ]
      },
    ]
  }),
  mounted(){
    console.log(this.totalProjects)
  },
  methods:{
   
  },
  computed: {
    totalProjects: function (){
      return this.projects.length - 1;
    }
  }
};
</script>

<style lang="scss">

  .projects{
    padding: 60px 0;
    color: #212529;

    .title{

      padding: 0 10%;

      p{
        color: #777;
        margin: 20px auto;
        font-weight: 400;
        font-size: 15px;
      }

    }
    

    

  }

   @media screen and (max-width: 980px) {

    .projects .title{
      padding: 0 2%;
    }

  }

</style>